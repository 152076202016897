<template>
  <div>
    <div class="facilityBox">
      <el-alert style="margin-bottom:10px;" type="warning" :closable="false" title="温馨提示：已认证司机、车辆根据司机身份证号与车牌号来判定，同人同车多次提交认证，视为认证1人、1车。">
      </el-alert>
      <!-- form表单 -->
      <el-form class="manageForm" :model="manageForm" :inline="true">
        <el-form-item label="企业名称：" prop="EnterpriseFullName">
          <el-input class="fromInp" v-model="manageForm.EnterpriseFullName" placeholder="输入企业名称" @keyup.enter.native="search()"></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="LegalPersonlPhone">
          <el-input class="fromInp" v-model="manageForm.LegalPersonlPhone" @keyup.enter.native="search()" placeholder="输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="提交日期：" prop="TaskDatetime">
          <el-date-picker v-model="manageForm.TaskDatetime" type="daterange" range-separator="至" start-placeholder="开始日期" value-format="yyyy-MM-dd" end-placeholder="结束日期" @change="searchChange()">
          </el-date-picker>
        </el-form-item>
        <el-form-item label-width="10px">
          <el-button type="primary" size="medium" @click="search()" icon="el-icon-search">搜索</el-button>
          <el-button type="primary" size="medium" @click="resetForm()" icon="el-icon-delete">清空</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="tableData" :header-cell-style="{ color: '#666', background: '#f0f0f0' }" v-loading="loading">
        <el-table-column type="index" align="center" label="序号" width="50" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="EnterpriseFullName" label="企业名称" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="EnterpriseCreditCode" label="企业统一社会信用代码" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="EnterpriseAddress" label="企业地址" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="SubmitDatetime" label="提交时间" width="150" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="Status" label="货主状态" width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.Status }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="DriverCnt" label="未认证司机数" width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff2323">{{ scope.row.DriverCnt }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="DriverCntCertified" label="已认证司机数" width="100" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="VehicleCnt" label="未认证车辆数" width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff2323">{{ scope.row.VehicleCnt }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="VehicleCntCertified" label="已认证车辆数" width="100" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="VerifyRemark" label="备注" width="100" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="340">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="approveDriver(scope.row)" icon="el-icon-user-solid">
              认证司机
            </el-button>
            <el-button type="primary" size="small" @click="approveCar(scope.row)" icon="el-icon-truck">
              认证车辆
            </el-button>
            <el-button type="primary" size="small" @click="goFacility(scope.row,pagination.page)" icon="el-icon-document">
              详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 10px">
        <!-- 分页 -->
        <el-pagination background class="pagination" @current-change="handleCurrentChange" :current-page.sync="pagination.page" :page-size="pagination.pagesize" layout="total, prev, pager, next, jumper" :total="pagination.total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { GetEnterprise } from "@/api/auditInfo/certified/index";
export default {
  data() {
    return {
      // 分页参数
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 列表数据
      tableData: [],
      // 筛选条件数据
      manageForm: {
        EnterpriseFullName: "",
        LegalPersonlPhone: "",
        TaskDatetime: ["", ""],
      },
      // 表格loading
      loading: true,
    };
  },
  created() {
    if (this.$route.query.pageIndex) {
      this.pagination.page = Number(this.$route.query.pageIndex)
    }
    // 获取列表数据
    this.GetEnterprise();
  },
  mounted() {
    // 监听浏览器刷新
    window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
  },
  destroyed() {
    // 移除浏览器刷新
    window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
  },
  methods: {
    searchChange() {
      this.search()
    },
    // 浏览器刷新重置参数
    beforeunloadHandler(e) {
      if (this.$route.path == '/auditInfo/certifiedOwner/index') {
        // 重置路由
        this.$router.push({
          path: "/auditInfo/certifiedOwner/index"
        });
      }
    },
    // 清空搜索条件
    resetForm() {
      this.loading = true;
      this.manageForm = {
        EnterpriseFullName: "",
        LegalPersonlPhone: "",
        TaskDatetime: ["", ""],
      };
      let data = {
        EnterpriseFullName: this.manageForm.EnterpriseFullName,
        LegalPersonlPhone: this.manageForm.LegalPersonlPhone,
        SubmitDatetimeStart: this.manageForm.TaskDatetime[0],
        SubmitDatetimeEnd: this.manageForm.TaskDatetime[1],
        pageSize: this.pagination.pagesize,
        pageIndex: 1,
      };
      GetEnterprise({ Json: JSON.stringify(data) }).then((res) => {
        this.tableData = res.enterpriseBase;
        this.pagination.total = Number(res.totalRowCount);
      }).finally(() => {
        this.loading = false;
      })
    },
    // 搜索
    search() {
      this.loading = true;
      let data = {
        EnterpriseFullName: this.manageForm.EnterpriseFullName,
        LegalPersonlPhone: this.manageForm.LegalPersonlPhone,
        SubmitDatetimeStart: this.manageForm.TaskDatetime[0],
        SubmitDatetimeEnd: this.manageForm.TaskDatetime[1],
        pageSize: this.pagination.pagesize,
        pageIndex: 1,
      };
      GetEnterprise({ Json: JSON.stringify(data) }).then((res) => {
        this.tableData = res.enterpriseBase;
        this.pagination.total = Number(res.totalRowCount);
      }).finally(() => {
        this.loading = false;
      })
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.loading = true;
      this.pagination.page = e;
      let data = {
        EnterpriseFullName: this.manageForm.EnterpriseFullName,
        LegalPersonlPhone: this.manageForm.LegalPersonlPhone,
        SubmitDatetimeStart: this.manageForm.TaskDatetime[0],
        SubmitDatetimeEnd: this.manageForm.TaskDatetime[1],
        pageSize: this.pagination.pagesize,
        pageIndex: e,
      };
      GetEnterprise({ Json: JSON.stringify(data) }).then((res) => {
        this.tableData = res.enterpriseBase;
        this.pagination.total = Number(res.totalRowCount);
      }).finally(() => {
        this.loading = false;
      })
    },
    // 查询列表（货主，服务商）列表
    GetEnterprise() {
      let data = {
        EnterpriseFullName: this.manageForm.EnterpriseFullName,
        LegalPersonlPhone: this.manageForm.LegalPersonlPhone,
        SubmitDatetimeStart: this.manageForm.TaskDatetime[0],
        SubmitDatetimeEnd: this.manageForm.TaskDatetime[1],
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
      };
      GetEnterprise({ Json: JSON.stringify(data) }).then((res) => {
        this.tableData = res.enterpriseBase;
        this.pagination.total = Number(res.totalRowCount);
      }).finally(() => {
        this.loading = false;
      })
    },
    // 跳转详情
    goFacility(item, pageIndex) {
      this.$router.push({
        path: "/auditInfo/certifiedOwner/certifiedInfo",
        query: { UserID: item.UserID, pageIndex },
      });
    },
    // 认证司机
    approveDriver(item) {
      this.$router.push({
        path: "/auditInfo/certifiedDriver/index",
        query: { UserID: item.UserID },
      });
    },
    // 认证车辆
    approveCar(item) {
      this.$router.push({
        path: "/auditInfo/certifiedCar/index",
        query: { UserID: item.UserID },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>